import React, { useEffect, useReducer, FC, useState } from 'react';
import { useResource } from '@tg/core/hooks';
import { Container, PageHeader } from '@tg/core/components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import type { User } from 'src/types';
import { handleTimeZone } from '@tg/core/utils/datetimeHelpers';
import Calendar, { dateReducer } from '../components/Calendar/Calendar';
import PendingEventList from './components/PendingEventList';
import type { Planner, CalendarData, PlannerEvent } from '../types';
import ApproveRejectEvent from './components/ApproveRejectEvent';

const userDataReducer = (
  state: CalendarData,
  action: {
    type: 'ADD' | 'UPDATE' | 'RESET';
    payload: {
      id: string;
      events: PlannerEvent[];
      user?: User; // required on ADD, not UPDATE
      publicHolidays: string[];
      holiday_entitlement: string[];
      totalEntitlement: string[];
      currentPeriod?: Number;
    };
  },
) => {
  switch (action.type) {
    case 'ADD': {
      return {
        ...state,
        [action.payload.id]: {
          user: action.payload.user,
          events: action.payload.events,
          publicHolidays: action.payload.publicHolidays,
          holiday_entitlement: action.payload.holiday_entitlement,
          totalEntitlement: action.payload.totalEntitlement,
          currentPeriod: action.payload.currentPeriod,
        },
      };
    }
    case 'RESET': {
      return userDataReducer;
    }
    case 'UPDATE': {
      const newState = { ...state };
      newState[action.payload.id].events = action.payload.events;
      return newState;
    }
    default:
      return state;
  }
};

const TimeOff: FC = () => {
  const { t } = useTranslation(['page_titles']);

  // The year we are looking at on the calendar
  const [state, dispatch] = useReducer(dateReducer, {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });
  const {
    data: calendarYearData,
    isFetching: calendarYearFetching,
    getResource: getCalendarData,
    errors: calendarYearDataErrors,
  } = useResource<Planner[]>({ url: `employers/planners` }, true);

  // Transform the data in to the format that the Calendar component wants.
  // This makes it easier to replace a single users Events without fetching
  // every user again
  const [calData, dispatchCalData] = useReducer(
    userDataReducer,
    {} as CalendarData,
  );

  const [currentYearData, setCurrentYearData] = useState();

  useEffect(() => {
    const today = handleTimeZone(new Date());
    if (calendarYearData) {
      dispatchCalData({
        type: 'RESET',
        payload: null,
      });
    }

    if (calendarYearData?.length) {
      calendarYearData.forEach(empData => {
        const { planner_years } = empData;
        const consolidatedEvents = [];
        planner_years.forEach(planner_year => {
          const { events } = planner_year;
          const plannerEvent = events.map(event => {
            return { ...event, planYear: planner_year.year };
          });
          consolidatedEvents.push(...plannerEvent);
        });
        const consolidatedPlanneryear_leaves = [];
        planner_years.forEach(planner_year => {
          consolidatedPlanneryear_leaves.push(
            ...planner_year.planneryear_leaves,
          );
        });

        let allPublicHoliday;
        if (consolidatedPlanneryear_leaves?.length) {
          allPublicHoliday = consolidatedPlanneryear_leaves?.filter(
            leave => leave.leave_type === 'public-holidays',
          );
        }

        const getPublicHolidays = () => {
          const uniquePublicHoliday = new Set();

          allPublicHoliday.forEach(obj => {
            obj.leave_type_value.forEach(value => {
              uniquePublicHoliday.add(value);
            });
          });

          const uniquePublicHolidayValues = [...uniquePublicHoliday];

          return uniquePublicHolidayValues;
        };

        let currentPlannerYear;
        if (planner_years?.length) {
          planner_years.forEach(plannerYear => {
            const { start_date, end_date } = plannerYear;
            const startDate = handleTimeZone(new Date(start_date));
            const endDate = handleTimeZone(new Date(end_date));
            if (today >= startDate && today <= endDate) {
              currentPlannerYear = plannerYear;
            }
          });
        }

        dispatchCalData({
          type: 'ADD',
          payload: {
            id: planner_years[0].contract_id,
            events: consolidatedEvents,
            user: empData?.employee?.user,
            publicHolidays: getPublicHolidays(),
            holiday_entitlement: currentPlannerYear?.entitlement_used,
            totalEntitlement: currentPlannerYear?.leave_available,
            currentPeriod: currentPlannerYear?.year,
          },
        });
      });
      const plannerYears = [] as Planner[];
      calendarYearData.forEach(plannerData => {
        plannerData.planner_years.forEach(plannerYear => {
          const { start_date, end_date } = plannerYear;
          if (start_date && end_date) {
            const startDate = new Date(start_date);
            const endDate = new Date(end_date);
            if (today >= startDate && today <= endDate) {
              plannerYears.push({
                employee: plannerData.employee,
                ...plannerYear,
              });
            }
          }
        });
      });

      setCurrentYearData(plannerYears);
    }
  }, [calendarYearData, state?.year]);

  return (
    <div className='mb-5'>
      <Container width='xl'>
        <Helmet title={t('page_titles:time_off')} />
        <PageHeader heading={t('page_titles:time_off')} />

        <div className='mb-10 md:max-w-xl'>
          <PendingEventList
            data={calData}
            calendarYearData={calendarYearData}
            loading={calendarYearFetching}
            onUpdate={() => {
              getCalendarData();
            }}
          />
        </div>

        <Calendar
          data={calData}
          renderEvents
          year={state.year}
          calendarYearData={calendarYearData}
          event_types={currentYearData}
          month={state.month}
          dispatch={dispatch}
          isFetching={calendarYearDataErrors}
          loading={calendarYearFetching}
          approveRejectButton={({ event, contractId, year }, onClose) => (
            <ApproveRejectEvent
              event={event}
              year={year}
              contractId={contractId}
              onUpdate={() => {
                getCalendarData();
                onClose();
              }}
            />
          )}
        />
      </Container>
    </div>
  );
};

export default TimeOff;
