import React, { FC, useState, useEffect, useReducer } from 'react';
import { useResource } from '@tg/core/hooks';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { PageHeader, Button } from '@tg/core/components';
import { handleTimeZone } from '@tg/core/utils/datetimeHelpers';
import Calendar, { dateReducer } from '../components/Calendar/Calendar';
import NewBooking from './NewBooking/NewBooking';
import {
  Planner,
  CalendarData,
  PlannerEventTypes,
  PlannerEvent,
} from '../types';

interface TimeOffProps {
  contractId: string;
}
const TimeOff: FC<TimeOffProps> = ({ contractId }) => {
  const { t } = useTranslation(['page_titles', 'time_off']);
  const [currentYearData, setCurrentYearData] = useState(null);
  const [event_types, setEventTypes] = useState(null);
  const [calendarYearsData, setCalendarYearsData] = useState([]);

  // The year we are looking at on the calendar
  const [state, dispatch] = useReducer(dateReducer, {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });
  const [holidayYear, setHolidayYear] = useState({
    startDate: '',
    endDate: '',
  });
  const [startDate, setStartDate] = useState({ month: 0, date: 1 });
  const [endDate, setEndDate] = useState({ month: 11, date: 31 });
  const [carryoverLeaves, setCarryoverLeaves] = useState({
    used: 0,
    totalDays: 0,
  });
  const {
    data: calendarYearData,
    isFetching: calendarYearFetching,
    getResource: getCalendarData,
    errors: calendarYearDataErrors,
  } = useResource<Planner[]>(
    { url: `employees/contracts/${contractId}/planners` },
    true,
  );

  // Transform the data in to the format that the Calendar component wants.
  // This makes it easier to replace a single users Events without fetching
  // every user again
  // TODO: useReducer
  const [calData, setCalData] = useState({} as CalendarData);

  useEffect(() => {
    if (calendarYearData?.length) {
      const singlecalDataCopy = {};
      calendarYearData.forEach(val => {
        const { events } = val;
        const locEvents = events.map(event => {
          return { ...event, planYear: val.year };
        });
        const yearWiseLeaveTypeOptions = val?.planneryear_leaves?.find(
          planneryear_leave =>
            planneryear_leave.leave_type === 'public-holidays',
        );
        if (!val) singlecalDataCopy[val.id] = {};
        singlecalDataCopy[val.id] = {
          user: {
            ...val.employee?.user,
            contract_id: val.contract_id,
          },
          events: locEvents,
          publicHolidays: yearWiseLeaveTypeOptions?.leave_type_value,
        };
      });

      setCalData(singlecalDataCopy);
      setCalendarYearsData(calendarYearData);
      const today = handleTimeZone(new Date());

      const currentYrData = calendarYearData.find(
        yearData =>
          today >= handleTimeZone(new Date(yearData.start_date)) &&
          today <= handleTimeZone(new Date(yearData.end_date)),
      );

      const carryOverLeaves =
        calendarYearData?.filter(item => item?.year == currentYrData?.year)[0]
          ?.leave_available?.['Carry Over '] ||
        calendarYearData?.filter(item => item?.year == currentYrData?.year)[0]
          ?.leave_available?.['Carry Over'];
      const carryOverLeavesUsed =
        calendarYearData?.filter(item => item?.year == currentYrData?.year)[0]
          ?.entitlement_used?.['Carry Over'] ||
        calendarYearData?.filter(item => item?.year == currentYrData?.year)[0]
          ?.entitlement_used?.['Carry Over '];
      setCarryoverLeaves({
        used: carryOverLeavesUsed,
        totalDays: carryOverLeaves,
      });
      if (currentYrData && currentYrData !== undefined) {
        setCurrentYearData(currentYrData);
        const {
          holiday_year: { start_date, end_date },
        } = currentYrData;
        setHolidayYear({
          startDate: start_date,
          endDate: end_date,
        });
      }
    }
  }, [calendarYearData, state?.year]);

  useEffect(() => {
    if (state && calendarYearData?.length) {
      const plannerYearLeaves = [];
      const { year, month } = state;
      const firstDayOfMonth = handleTimeZone(new Date(year, month, 1));
      const lastDayOfMonth = handleTimeZone(new Date(year, month + 1, 0));
      calendarYearData.forEach(calendarYear => {
        const startDt = handleTimeZone(new Date(calendarYear.start_date));
        const endDt = handleTimeZone(new Date(calendarYear.end_date));
        if (
          (firstDayOfMonth >= startDt && firstDayOfMonth <= endDt) ||
          (lastDayOfMonth >= startDt && lastDayOfMonth <= endDt)
        ) {
          if (calendarYear) {
            calendarYear.planneryear_leaves.forEach(planneryear_leave => {
              if (
                !plannerYearLeaves.find(
                  plannerYearLeave =>
                    plannerYearLeave.colour_code ===
                    planneryear_leave.colour_code,
                )
              ) {
                plannerYearLeaves.push(planneryear_leave);
              }
            });
          }
        }
        if (plannerYearLeaves?.length) {
          setEventTypes(plannerYearLeaves);
        } else {
          setEventTypes(null);
        }
      });
    }
  }, [calendarYearData, state.month]);

  useEffect(() => {
    if (holidayYear?.startDate && holidayYear.endDate) {
      const startDateCopy = holidayYear.startDate.split('/');
      const endDateCopy = holidayYear.endDate.split('/');
      const startDateMonth = Number(startDateCopy[1]) - 1;
      const startDateDay = Number(startDateCopy[0]);

      const endDateMonth = Number(endDateCopy[1]) - 1;
      const endDateDay = Number(endDateCopy[0]);

      setStartDate({
        month: startDateMonth,
        date: startDateDay,
      });
      setEndDate({
        month: endDateMonth,
        date: endDateDay,
      });
    }
  }, [holidayYear]);

  const [addEditModalType, setAddEditModalType] = useState(
    undefined as Omit<PlannerEventTypes, 'unpaid'>,
  );
  // For editing an existing event
  const [editModalEvent, setEditModalEvent] = useState(
    undefined as PlannerEvent,
  );
  useEffect(() => {
    if (editModalEvent) {
      const eventType =
        editModalEvent.event_type === 'unpaid'
          ? 'holiday'
          : editModalEvent.event_type;
      setAddEditModalType(eventType);
    }
  }, [editModalEvent]);

  const remainingDays =
    currentYearData &&
    currentYearData?.leave_available?.holiday -
      currentYearData.entitlement_used.holiday;

  return (
    <>
      <Helmet title={t('page_titles:time_off')} />
      <PageHeader
        heading={t('page_titles:time_off')}
        description={
          currentYearData && (
            <>
              <div>
                <p>
                  <b>Holiday Leave:</b>{' '}
                  {t('time_off:employee.summary', {
                    currentYear: currentYearData?.year,
                    entitlement:
                      currentYearData?.leave_available?.holiday > 0
                        ? Number(
                            currentYearData?.leave_available?.holiday,
                          )?.toFixed(1)
                        : 0,
                    remaining:
                      remainingDays > 0 ? Number(remainingDays)?.toFixed(1) : 0,
                  })}
                </p>
                <p>
                  <b>Carry-Over Leave: </b>

                  {t('time_off:employee.summary_carry_over', {
                    currentYear: currentYearData?.year,
                    entitlement: Number(carryoverLeaves?.totalDays || 0),
                    remaining:
                      Number(carryoverLeaves?.totalDays || 0) -
                        Number(carryoverLeaves?.used || 0) <
                      0
                        ? 0
                        : Number(carryoverLeaves?.totalDays || 0) -
                          Number(carryoverLeaves?.used || 0),
                  })}
                </p>
              </div>
            </>
          )
        }
        action={
          !calendarYearDataErrors.length && (
            <div className='flex'>
              <Button
                onClick={() => {
                  setAddEditModalType('holiday');
                }}
                disabled={calendarYearFetching}
              >
                {t('time_off:employee.request_leave')}
              </Button>
            </div>
          )
        }
      />

      <Calendar
        loading={calendarYearFetching}
        data={calData}
        renderEvents
        year={state.year}
        month={state.month}
        dispatch={dispatch}
        wrapDays
        calendarYearsData={calendarYearsData}
        userType='employee'
        currentYearData={currentYearData}
        allowEditingEvents
        event_types={event_types}
        isFetching={calendarYearDataErrors}
        onEditEventClick={event => setEditModalEvent(event)}
        onSuccess={() => getCalendarData()}
      />

      <NewBooking
        isOpen={!!addEditModalType}
        onClose={() => {
          setAddEditModalType(undefined);
          setEditModalEvent(undefined);
        }}
        existingEvent={editModalEvent}
        contractId={contractId}
        eventType={addEditModalType}
        event_types={event_types}
        onSuccess={() => {
          getCalendarData();
        }}
        year={currentYearData?.year}
        getCalendarData={getCalendarData}
        startDate={startDate}
        endDate={endDate}
        calendarYearsData={calendarYearsData}
      />
    </>
  );
};

export default TimeOff;
